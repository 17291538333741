import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

const BabyRappers = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="The Best 'Baby' Rapper?" />
        <meta name="twitter:image" content="http://www.shoya.co/static/shoyaright_icon-ada66d9717351d5e161cdd4c5d83603b.png" />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <div className="article">
        <Link to="/shoyaright">
        <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p> The Best "Baby" Rapper?</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Feb. 4th, 2020) </p>
        </div>
        <div className="articleBody">
        <p>
        A few months ago, I saw a viral tweet. It was a quote tweet, with an image, that quoted a tweet with two more images.
        </p>
        <p>
        The two images in the quoted tweet were photos, one of Lil Baby and the other of DaBaby. You might know the one I mean -- the text said something along the lines of 'who's the best Baby rapper?'
        </p>
        <p>
        Anyway the outer tweet, the one that actually showed up on my TL, the one with just one image, was also a photo. It was a photo of Sada Baby aka Skuba Steve. I forget the text that went along with it, but the author was displeased that Sada Baby wasn't mentioned in the original tweet. They prolly said some internet shit like 'put some respect on his name' (which, yehmean, shoutout to the original baby, Baby).        </p>
        <p>
        Now don't get me wrong, I respect all three of these artists and enjoy (various amounts of) their music.
        </p>
        <p>
        I love those videos of Meatball dancing to "Whoa" on Instagram. I love that DaBaby facilitated a comeback for the Jabbawockeez. I love that the Safdies directed that video for Sada Baby (s/o Rivers for being the only one to like my tweet).
        </p>
        <p>
        But here's where I get editorial. If y'all think for one single solitary seco-- wait, what was that? Did y'all hear that? Wait naw for real, shhh, I'm not playing…
        </p>
        <p>
        "ALALALALA OO-WOOOOO"
        </p>
        <p>
        If y'all think for one single solitary second that the best "Baby" in rap music (in all of contemporary music for that matter) isn't <i>also</i> the King of the Jungle, isn't <i>also</i> the Biggest Squid in the Sea, then y'all got something else coming!!!!!!!!
        </p>
        <p>
        I suggest y'all pay a visit to Anime World. I suggest y'all spend some time in the cracks and the crevices.
        </p>
        <p>
        His only blemish was a collaboration with scum-on-earth, the urinary supervillain Robert Kelly (way before the documentaries dropped, I should add).
        </p>
        <p>
        Squares, I'm talking Sahbabii. Case closed.
        </p>
        <p>
        Honorable mention to Babyface Ray though (and word to Sue for putting me on).
        </p>
        </div>
        <p className="plug">If you enjoyed this article, consider <a href="https://www.patreon.com/join/shoyaright?">becoming a patron</a> or making a <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">one-time pledge</a>. Support corporate journalism!</p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020</p>
        </div>
      </div>
    </div>

  );
};
export default BabyRappers;
